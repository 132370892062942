import React, { useContext } from "react";
import styled from "@emotion/styled";
import { mediaQueries } from "@src/styles/theme";
import Container from "@src/components/Container";
import { Title, Space, BodyText, Heading, theme } from "@src/components/nessie-web";
import SEO from "@src/components/SEO";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImageWrapper from "@src/components/GatsbyImageWrapper";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const {
  space: { dt_xxl, dt_xl, dt_m },
} = theme;

const HeroSection = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  min-height: 480px;
  background-color: #90e3ff;
  background-image: url("${(props) => props.backgroundImage}");
  background-size: 1080px;
  background-position: bottom -10px center;
  background-repeat: no-repeat;
  padding: 0 ${dt_m}px;

  ${mediaQueries[0]} {
    background-size: 1440px;
  }

  ${mediaQueries[1]} {
    min-height: 480px;
    background-size: 1920px;
  }

  ${mediaQueries[2]} {
    min-height: 550px;
    background-size: 2560px;
  }

  @media (min-width: 2560px) {
    min-height: 700px;
    background-size: 125%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: ${dt_xxl}px;
`;

const CTASection = styled.div`
  width: 100%;
  padding: ${dt_m}px 0;

  ${mediaQueries[0]} {
    padding: ${dt_xxl}px 0;
  }
`;

const FlexContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${dt_xxl}px 0;

  ${mediaQueries[0]} {
    flex-direction: row;
  }
`;

const ContentChild = styled.div`
  width: 100%;
  padding: 0 ${dt_m}px;

  ${mediaQueries[0]} {
    width: 50%;
  }
`;

const CellphoneImg = styled.img`
  margin: auto;
  display: block;
  padding-bottom: ${dt_xl}px;

  ${mediaQueries[0]} {
    padding-bottom: 0;
  }
`;

const FamilyConundrums = () => {
  const data = useStaticQuery(graphql`
    {
      apple: file(name: { eq: "app-store-btn" }) {
        childImageSharp {
          gatsbyImageData(width: 228, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      directus {
        page_family_conundrums {
          hero_image {
            id
            filename_download
            filename_disk
          }
          cta_image {
            id
            filename_disk
          }
          cta_button_url
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    apple,
    directus: {
      page_family_conundrums: { hero_image, cta_image, cta_button_url },
    },
  } = data;

  const t = useContext(TranslationContext);

  return (
    <>
      <SEO
        title="Try Conundrums"
        description="Fun puzzles for the whole family to noodle over, available on the Discover tab in the ClassDojo app"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Try Conundrums",
          description:
            "Fun puzzles for the whole family to noodle over, available on the Discover tab in the ClassDojo app",
        }}
      />
      <HeroSection backgroundImage={hero_image.file.publicURL}>
        <Container>
          <Content>
            <Title>{t.translate("directus.page_family_conundrums.hero_title")}</Title>
            <Space size="m" />
            <BodyText>{t.translate("directus.page_family_conundrums.hero_text")}</BodyText>
            <Space size="m" />
            <a href={cta_button_url}>
              <GatsbyImageWrapper
                image={apple}
                alt={t.translate("directus.page_family_conundrums.cta_button_img_alt").toString()}
              />
            </a>
          </Content>
        </Container>
      </HeroSection>

      <CTASection>
        <Container>
          <FlexContent>
            <ContentChild>
              <CellphoneImg src={cta_image.file.publicURL} alt="Conundrums Cellphone" />
            </ContentChild>
            <ContentChild>
              <Heading>{t.translate("directus.page_family_conundrums.cta_title")}</Heading>
              <Space size="m" />
              <BodyText>{t.translate("directus.page_family_conundrums.cta_text")}</BodyText>
              <Space size="m" />
              <a href={cta_button_url}>
                <GatsbyImageWrapper
                  image={apple}
                  alt={t.translate("directus.page_family_conundrums.cta_button_img_alt").toString()}
                />
              </a>
            </ContentChild>
          </FlexContent>
        </Container>
      </CTASection>
    </>
  );
};

export default FamilyConundrums;
